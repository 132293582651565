import React from "react";
import { Link } from "gatsby";
import Contact from "../Contact"
import Subscribe from "../Contact/Subscribe"
import style from "./Footer.module.css"

const ContactInfo = () =>
    <div className="uk-width-1-3@m uk-flex-first@m">
        <h3>Find US</h3>
        <p><span className="uk-h4 uk-text-bold">Singapore</span><br />
            BetterTradeOff Pte Ltd<br />
            31 Boon Tat Street, #02-01<br />
            Singapore 069625</p>
        <p><span className="uk-h4 uk-text-bold">Canada</span><br />
            Solutions BetterTradeOff Amériques Inc.<br />
            Station Fintech, 2&egrave; et 3&egrave; &eacute;tage<br />
            4 Place Ville-Marie<br />
            Montr&eacute;al, QC H3B 2E7<br />
            Canada</p>
        <p><span className="uk-h4 uk-text-bold">Dubai</span><br />
            BetterTradeOff Fintech Solutions Ltd<br />
            Office 208 – 215 Fintech Hive<br />
            Level 1 Gate Avenue South Zone<br />
            Dubai International Financial Centre<br />
            P O Box 506520<br />
            Dubai, United Arab Emirates<br />
            </p>
        <a href="https://twitter.com/bettertradeoff" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="twitter" />
        <a href="https://www.facebook.com/bettertradeoff/" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="facebook" />
        <a href="https://www.linkedin.com/company/bettertradeoff-pte-ltd" className="uk-icon-button uk-margin-small-right" target="_blank" data-uk-icon="linkedin" />
    </div>

const Links = () =>
    <div className="footer-links uk-grid uk-margin-large-top">
        <div className="uk-width-3-5@m">
            <div className="uk-grid uk-grid-collapse uk-child-width-1-2 uk-child-width-1-3@m" data-uk-grid>
                <div>
                    <ul className="uk-list uk-list">
                        <li className="uk-h4 uk-text-bold">Discover</li>
                        <li><Link to={`/about-us`}>About Us</Link></li>
                        <li><a href={`https://www.upplan.sg/blog`} target="_blank" rel="noopen">Blog</a></li>
                        <li><Link to={`/careers`}>Careers</Link></li>
                    </ul>
                </div>
                <div>
                    <ul className="uk-list uk-list">
                        <li className="uk-h4 uk-text-bold">Legal</li>
                        <li><Link to={`/bto-terms-and-conditions-of-use`}>Terms & Conditions</Link></li>
                        <li><Link to={`/bto-privacy-and-data-protection-notice`}>Privacy Policy</Link></li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="uk-width-expand">
            <div className="uk-width-1-1">
                {/*<p className="uk-h4 uk-text-bold">Sign up for our newsletter</p>
                <Subscribe />*/}
            </div>
        </div>
    </div>

const Footer = () =>
    <section className={`${style.footerContent} footer uk-section uk-section-large uk-section-muted uk-padding-remove-bottom`}>
        <div className="uk-container">
            <h2 className="uk-text-center uk-margin-large bottom">Contact us</h2>
            <div className="uk-grid uk-grid-large" data-uk-grid >
                <ContactInfo />
                <div className="uk-width-2-3@m uk-flex-first">
                    <Contact />
                </div>
            </div>
            <Links />
        </div>
        <div className="uk-container uk-padding-remove-bottom uk-margin-remove-bottom">
            <div className="uk-text-center uk-padding uk-padding-remove-horizontal">
                <span className="uk-text-small uk-text-muted">&copy; 2018-2023 <a href="https://www.bettertradeoff.com">BetterTradeOff Solutions Pte. Ltd.</a> All Rights Reserved.</span>
            </div>
        </div>
    </section>

export default Footer;
