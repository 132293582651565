import React, { useEffect, useState } from "react";
import axios from "axios"
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import style from "./Contact.module.css";

async function postForm(body) {
    const url = process.env.GATSBY_CONTACT_FORM_ENDPOINT;
    console.log("POST FORM BODY", body)
    const result = await axios.post(url, body, {
            headers: {
                'Accept': 'application/json'
            }
        })
    console.log("FORM RES", result)
    const res = await result.data;

    return res;
}

const Contact = props => {
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        errors,
        watch,
        formState
    } = useForm({
        mode: "all",
    });
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalShown, setIsModalShown] = useState(false)
    const [responseMessage, setResponseMessage] = useState({
        subject: "Subject line",
        content: "Message content"
    })

    useEffect(
        () => {
            if(document.getElementById("SubmitContactForm") && document.getElementById("SubmitContactForm").length) {
                document.getElementById("SubmitContactForm").disabled = true;
            }
        }, [isSubmitting]
    )

    const resetForm = () => {
        setIsModalShown(false)
    }

    const submit = async (data, e) => {
        e.preventDefault()
        setIsSubmitting(true)

        const body = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            organization: data.organization,
            message: data.message,
            timestamp: Date.now(),
        }

        postForm(body)
        .then(function (response) {
            console.log("POST FORM RESPONSE", response)
            setResponseMessage({
                subject: response.message,
                content: "Thank you for contacting us. We will respond shortly."
            })
            setIsModalShown(true)
            setIsSubmitting(false)
            document.getElementById("contactForm").reset();
        })
        .catch(function (error) {
            console.log("FIRST CATCH ERROR", error)
            console.log("FIRST CATCH ERROR", error.response)
            setIsSubmitting(false)
        })

    };

    const handleFileChange = e => {
        console.log("GOT A FILE", e.target.value)
    }

    return (
        <form id="contactForm" className="default-form" onSubmit={(e) => handleSubmit(submit)(e)}>
            <fieldset className="uk-fieldset uk-text-left">
                <div className="uk-grid uk-grid-medium uk-child-width-1-2@s" data-uk-grid>
                    <div className="uk-margin-small-top">
                        <label htmlFor="first_name" className={`uk-text-small ${errors.first_name ? "uk-text-danger" : "uk-text-muted"}`}>
                        {errors.first_name ?
                        <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                        :
                        <small className="uk-text-danger uk-display-inline">*</small>} First name {errors.first_name?.type === "required" && "is required"}
                            <input
                                id="first_name"
                                name="first_name"
                                ref={register({required: true})}
                                type="text"
                                className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                autoComplete="off"
                                autoCorrect="off"
                                required
                            />
                        </label>
                    </div>

                    <div className="uk-margin-small-top">
                        <label htmlFor="last_name" className={`uk-text-small ${errors.last_name ? "uk-text-danger" : "uk-text-muted"}`}>
                        {errors.last_name ?
                        <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                        :
                        <small className="uk-text-danger uk-display-inline">*</small>} Last name {errors.last_name?.type === "required" && "is required"}
                            <input
                                id="last_name"
                                name="last_name"
                                ref={register({required: true})}
                                type="text"
                                className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                autoComplete="off"
                                autoCorrect="off"
                                required
                            />
                        </label>
                    </div>
                </div>

                <div className="uk-margin-small-top">
                    <label htmlFor="email" className={`uk-text-small ${errors.email ? "uk-text-danger" : "uk-text-muted"}`}>
                    {errors.email ?
                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                    :
                    <small className="uk-text-danger uk-display-inline">*</small>} Email address {
                        errors.email?.type === "required" && "is required" || errors.email && errors.email.message
                    }
                        <input
                            id="email"
                            name="email"
                            ref={register({
                              required: true,
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "is invalid"
                              }
                            })}
                            type="text"
                            className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                            autoComplete="off"
                            autoCorrect="off"
                            required
                        />
                    </label>
                </div>

                <div className="uk-margin-small-top">
                    <label htmlFor="organization" className={`uk-text-small uk-text-muted`}>
                        Company or organization (optional)
                        <input
                            id="organization"
                            name="organization"
                            ref={register}
                            type="text"
                            className="uk-input uk-form-large uk-border-rounded"
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </label>
                </div>

                <div className="uk-margin-small-top">
                    <label htmlFor="message" className={`uk-text-small ${errors.message ? "uk-text-danger" : "uk-text-muted"}`}>
                    {errors.message ?
                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                    :
                    <small className="uk-text-danger uk-display-inline">*</small>} Your message
                        <textarea
                            id="message"
                            name="message"
                            ref={register({required: true})}
                            rows="3"
                            className={`uk-textarea uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                            maxLength="1000"
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </label>
                </div>

                {!isLoading ?

                <div className="uk-text-center uk-margin-large">
                    {!formState.isValid  ?
                        <span style={{color: "#d8d8d8"}} className="uk-text-uppercase uk-text-small">Fill out required fields</span>
                    :
                        isSubmitting ?
                        <span style={{color: "#666"}} className="uk-text-uppercase uk-text-small">Just a moment</span>
                        :
                        <button
                            id="SubmitContactForm"
                            type="submit"
                            className={`uk-button uk-button-default uk-button-large uk-border-pill`}
                            >
                            Send your message
                        </button>
                    }
                </div>
                :
                null
                }
            </fieldset>
            <Modal
                isShown={isModalShown}
                style={{borderStyle: "none"}}>
                <div className="uk-card uk-card-default">
                    <div className="uk-card-header">
                        <h4>{responseMessage.subject}</h4>
                    </div>
                    <div className="uk-card-body">
                        <p>{responseMessage.content}</p>
                        <div className="uk-text-center uk-margin-large">
                            <button
                                className="uk-button uk-button-primary uk-border-pill"
                                onClick={resetForm}>
                                Okay
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </form>
    )

}


// class Contact extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             firstName: "",
//             lastName: "",
//             email: "",
//             phone: "",
//             company: "",
//             comment: "",
//             responseSubject: '',
//             responseMessage: '',
//             isShowing: false
//         };
//
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }
//
//     handleSubmit = (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
//         const endpoint = process.env.REACT_APP_FORMCARRY_ENDPOINT;
//         const body = {
//                 firstName: values.firstName,
//                 lastName: values.lastName,
//                 email: values.email,
//                 phone: values.phone,
//                 company: values.company,
//                 comment: values.comment
//             };
//
//         // axios.post(endpoint, body)
//         // .then((response) => {
//         //     let msg, title;
//         //     if (response.data.status === 'success') {
//         //         resetForm()
//         //         title = "Message received!"
//         //         msg = "Thank you, we'll follow up with you shortly.";
//         //     } else {
//         //         title = "There was a problem.";
//         //         msg = "Your message didn't go through, please try again.";
//         //     }
//         //     this.setState({
//         //         responseSubject: title,
//         //         responseMessage: msg,
//         //         isShowing: true
//         //     })
//         // })
//         // .catch((error) => {
//         //     console.log("BIG ERROR", error);
//         // });
//     }
//
//     resetForm = () => {
//         this.setState({
//            ...this.state,
//            firstName: "",
//            lastName: "",
//            email: "",
//            phone: "",
//            company: "",
//            comment: "",
//            isShowing: false
//        })
//     }
//
//     render() {
//         return (
//             <>
//             <Formik
//                 initialValues={{
//                     firstName: "",
//                     lastName: "",
//                     email: "",
//                     phone: "",
//                     company: "",
//                     comment: ""
//                 }}
//                 validationSchema={getSchema}
//                 onSubmit={this.handleSubmit}
//                 render={({ handleChange, handleBlur, values, errors, touched, isSubmitting, validateForm, isValid }) => (
//                     <Form className={`${style.btoForm}`}>
//                         <fieldset className="uk-fieldset">
//                             <div className="uk-margin-small-top uk-child-width-1-2@s" data-uk-grid>
//                                 <label htmlFor="firstName" className="uk-text-muted">First name {errors.firstName && touched.firstName && "* Required"}
//                                     <Field
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         value={values.firstName}
//                                         name="firstName"
//                                         label={errors.firstName && touched.firstName ? errors.firstName : "First name"}
//                                         placeholder={errors.firstName && touched.firstName && errors.firstName}
//                                         className={`uk-input uk-form-large uk-border-rounded ${errors.firstName && touched.firstName ? "uk-form-danger" : "uk-form"}`}
//                                         required
//                                     />
//                                 </label>
//                                 <label htmlFor="lastName" className="uk-text-muted">Last name  {errors.lastName && touched.lastName && "* Required"}
//                                     <Field
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         value={values.lastName}
//                                         name="lastName"
//                                         label={errors.lastName && touched.lastName ? errors.lastName : "Last name"}
//                                         placeholder={errors.lastName && touched.lastName && errors.lastName}
//                                         className={`uk-input uk-form-large uk-border-rounded ${errors.lastName && touched.lastName ? "uk-form-danger" : "uk-form"}`}
//                                         required
//                                     />
//                                 </label>
//                             </div>
//                             <div className="uk-margin-small-top">
//                                 <label htmlFor="email" className="uk-text-muted">Email address  {errors.email && touched.email && "* Required"}
//                                     <Field
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         value={values.email}
//                                         name="email"
//                                         label="Email address"
//                                         placeholder={errors.email && touched.email && errors.email}
//                                         className={`uk-input uk-form-large uk-border-rounded ${errors.email && touched.email ? "uk-form-danger" : "uk-form"}`}
//                                         required
//                                     />
//                                 </label>
//                             </div>
//
//                             <div className="uk-margin-small-top">
//                                 <label htmlFor="company" className="uk-text-muted">Company or organization (optional)
//                                     <Field
//                                         onChange={handleChange}
//                                         onBlur={handleBlur}
//                                         value={values.company}
//                                         name="company"
//                                         label={errors.company && touched.company ? errors.company : "Company"}
//                                         placeholder={errors.company && touched.company && errors.company}
//                                         className={`uk-input uk-form-large uk-border-rounded ${errors.company && touched.company ? "uk-form-danger" : "uk-form"}`}
//                                     />
//                                 </label>
//                             </div>
//                             <div className="uk-margin-small-top">
//                                 <label htmlFor="comment" className="uk-text-muted">Your message {errors.comment && touched.comment && "* Required"}
//                                 <Field
//                                     component="textarea"
//                                     name="comment"
//                                     label={errors.comment && touched.comment ? errors.comment : "Your message"}
//                                     placeholder={errors.comment && touched.comment && errors.comment}
//                                     rows="4"
//                                     className={`uk-textarea uk-form-large uk-border-rounded ${errors.comment && touched.comment ? "uk-form-danger" : null}`}
//                                     required
//                                 />
//                                 </label>
//                             </div>
//
//                             <div className="uk-margin-medium-top uk-text-right">
//                                 <button
//                                     type="submit"
//                                     className="uk-button uk-button-large uk-button-secondary uk-border-pill"
//                                     disabled={isSubmitting || !isValid}>
//                                     <span data-uk-icon="mail" /> &nbsp;Send message
//                                 </button>
//                             </div>
//                         </fieldset>
//                     </Form>
//                 )}
//             />
//
//             <Modal
//                 isShown={this.state.isShowing}
//                 style={{borderStyle: "none"}}>
//                 <div className="uk-card uk-card-small uk-card-default">
//                     <div className="uk-card-header">
//                         <h4 className="uk-text-primary">{this.state.responseSubject}</h4>
//                     </div>
//                     <div className="uk-card-body">
//                         <p>{this.state.responseMessage}</p>
//                         <div className="uk-text-center uk-margin-large">
//                             <button
//                                 className="uk-button uk-button-small uk-button-text"
//                                 onClick={this.resetForm}>
//                                 Okay
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </Modal>
//             </>
//         )
//     }
// }

export default Contact;
