import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo";

import Navigation from "./Navigation"
import Footer from "./Footer"

const Layout = ({ children }) => {

  useEffect(
        () => {
            try {
                const UIkit = require("uikit/dist/js/uikit");
                const Icons = require("uikit/dist/js/uikit-icons");
                UIkit.use(Icons);
            } catch (e) {
                console.error("UIKit ERROR", e);
            }
        }, []
    )

  return (
    <>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: '!function(e,t,a,n){e="//tags.tiqcdn.com/utag/bettertradeoff/main/prod/utag.js",t=document,a="script",n=t.createElement(a),n.src=e,n.type="text/java"+a,n.async=!0,e=t.getElementsByTagName(a)[0],e.parentNode.insertBefore(n,e)}();'
            }]}
        />
        <Navigation />
        <main>
        {children}
        </main>
        <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
