import { Link } from "gatsby"
import React from "react"
import style from "./Navigation.module.css";
import logo from "../../images/bto-logo.png";

const OffCanvas = () =>
    <div className={`uk-navbar-container uk-navbar-transparent uk-hidden@m`}
        data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small;">
        <nav className={`${style.mobileNav}`} data-uk-navbar>
            <div className={`uk-navbar-center`}>
                <div className={`${style.Logo} uk-navbar-item uk-logo`}>
                    <Link to={`/`}><img src={logo} alt="Logo" /></Link>
                </div>
            </div>
            <div className="uk-navbar-left">
                <button
                    type="button"
                    data-uk-toggle="target: #mobileNav"
                    className="uk-button-text uk-navbar-toggle uk-navbar-item"
                    data-uk-navbar-toggle-icon />
            </div>
        </nav>
        <div id="mobileNav" data-uk-offcanvas="mode: push">
            <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-text-left">
                <button
                    className="uk-position-top-left uk-position-small uk-offcanvas-close"
                    type="button"
                    data-uk-close />
                <ul className="uk-nav uk-nav-primary uk-margin-auto-vertical uk-nav-parent-icon uk-width-5-6" data-uk-nav>
                    <li className=""><a href={`/contact-us`}>Contact Us</a></li>
                    <li className="uk-parent">
                        <a href={`#`}>About Us</a>
                        <ul className="uk-nav-sub">
                            <li><a href={`/about-us`}>Our Mission</a></li>
                            <li><a href={`/financial-inclusion`}>Financial Inclusion</a></li>
                            <li><a href={`/our-team`}>Our Team</a></li>
                            <li><a href={`/careers`}>Careers</a></li>
                        </ul>
                    </li>
                    <li><a href={`/our-solutions`}>Our Solutions</a></li>
                    <li><a href={`/in-the-news`}>In the News</a></li>
                </ul>
            </div>
        </div>
    </div>

const Navigation = ({...props}) => {
    return (
        <>
        <div className={`${style.nav}`} data-uk-sticky="cls-active: uk-navbar-sticky uk-box-shadow-small;">
            <div className="uk-container">
                <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                    <div className="uk-navbar-center">
                        <ul className="uk-navbar-nav">
                            <li><Link to={`/contact-us`}>Contact Us</Link></li>
                            <li>
                                <Link to={`#`} onClick={(e) => {
                                    e.preventDefault()
                                    return
                                }}>About Us</Link>
                                <div
                                    className="uk-navbar-dropdown"
                                    data-uk-drop="animation: uk-animation-slide-bottom-medium; offset: -10">
                                    <ul className="uk-nav uk-navbar-dropdown-nav">
                                        <li><Link to={`/about-us`}>Our Mission</Link></li>
                                        <li><Link to={`/financial-inclusion`}>Financial Inclusion</Link></li>
                                        <li><Link to={`/our-team`}>Our Team</Link></li>
                                        <li><Link to={`/careers`}>Careers</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li className={`${style.Logo} uk-navbar-item uk-logo`}>
                                <Link to={`/`}><img src={logo} alt="Logo" /></Link>
                            </li>
                            <li><Link to={`/our-solutions`}>Our Solutions</Link></li>
                            <li><Link to={`/in-the-news`}>In the News</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
        <OffCanvas />
        </>
    )
}

export default Navigation;
